<template>
  <div
    class="relative my-auto"
    :class="{
      'opacity-50 pointer-events-none': disabled,
    }"
    ref="reactionContainer"
  >
    <div
      class="cursor-pointer select-none transition active:scale-90 p-[5px] flex space-x-1 items-center justify-center rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:active:bg-neutral-700 text-neutral-700 dark:text-neutral-300 text-xs sm:text-sm font-medium"
      title="React to this post"
      ref="reactionButton"
      @click="
        userReaction ? $emit('removeReaction') : $emit('addReaction', 'like')
      "
    >
      <AsyncImage
        v-if="userReaction"
        :class="{
          'h-5 w-5': small,
          'h-6 w-6': !small,
        }"
        :src="getAbsoluteAssetLink(`nami/reactions/${userReaction}.png`)"
      />
      <IconHeart v-else :size="small ? 20 : 24" />
      <div>React</div>
    </div>
    <transition
      enterActiveClass="transition"
      enterFromClass="scale-75 opacity-0"
      enterToClass="scale-100 opacity-100"
      leaveActiveClass="transition"
      leaveFromClass="scale-100 opacity-100"
      leaveToClass="scale-75 opacity-0"
    >
      <ul
        v-if="showReactions"
        class="absolute z-[100] left-0 bottom-full p-2 bg-neutral-200 dark:bg-neutral-700 rounded-md shadow-xl flex gap-2 w-64 cursor-pointer"
        style="transform-origin: bottom left"
      >
        <li v-for="reaction in reactions" ref="reactionItems">
          <Tooltip variant="custom" no-pointer-events>
            <template #tooltip>
              <span
                class="pointer-events-auto capitalize text-sm bg-black text-white rounded-full px-2 py-1 select-none"
              >
                {{ reaction.name }}
              </span>
            </template>
            <AsyncImage
              :src="reaction.icon"
              :alt="`nami-reaction-${reaction.name}`"
              class="w-8 h-8 object-contain cursor-pointer select-none transition hover:scale-125 active:scale-125"
              @click="
                () => {
                  userReaction === reaction.name
                    ? $emit('removeReaction')
                    : $emit('addReaction', reaction.name);
                  showReactions = false;
                }
              "
            />
          </Tooltip>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { IconHeart } from "@tabler/icons-vue";
import { useEventListener } from "@vueuse/core";
import type { ReactionType } from "~/src/api";

defineProps<{
  userReaction: ReactionType | null;
  small?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "addReaction", v: ReactionType): void;
  (e: "removeReaction"): void;
}>();

const reactionContainer = ref<HTMLDivElement>();
const reactionButton = ref<HTMLDivElement>();
const reactionItems = ref<HTMLLIElement[]>([]);
const showReactions = ref(false);

let timer: NodeJS.Timeout | null = null;

function handleDown() {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(() => {
    showReactions.value = true;
    timer = null;
  }, 250);
}

function handleUp(delayed: boolean = true) {
  if (timer) {
    clearTimeout(timer);
  }

  function execute() {
    showReactions.value = false;
    timer = null;
  }

  if (delayed) {
    timer = setTimeout(execute, 500);
  } else {
    execute();
  }
}

function handleCancel(e: TouchEvent | MouseEvent) {
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }

  if (!reactionContainer.value) {
    return;
  }

  if (!isClickInsideElements(e, [reactionContainer.value])) {
    showReactions.value = false;
  }
}

useEventListener(reactionContainer, "mouseenter", handleDown);
useEventListener(reactionContainer, "touchstart", handleDown);
useEventListener(reactionContainer, "mouseleave", () => handleUp(true));
useEventListener(document, "touchend", handleCancel);
useEventListener(document, "touchcancel", handleCancel);
useEventListener(document, "click", handleCancel);

const reactions: { name: ReactionType; icon: string }[] = [
  {
    name: "like",
    icon: getAbsoluteAssetLink("nami/reactions/like.png"),
  },
  {
    name: "love",
    icon: getAbsoluteAssetLink("nami/reactions/love.png"),
  },
  {
    name: "funny",
    icon: getAbsoluteAssetLink("nami/reactions/funny.png"),
  },
  {
    name: "wow",
    icon: getAbsoluteAssetLink("nami/reactions/wow.png"),
  },
  {
    name: "sad",
    icon: getAbsoluteAssetLink("nami/reactions/sad.png"),
  },
  {
    name: "support",
    icon: getAbsoluteAssetLink("nami/reactions/support.png"),
  },
];
</script>
