<template>
  <NamiModal
    :modelValue="modelValue"
    @update:modelValue="(v) => $emit('update:modelValue', v)"
  >
    <NamiModalTitle @close="$emit('update:modelValue', false)">
      Reactions
    </NamiModalTitle>
    <div class="space-y-4 text-sm sm:text-base">
      <ul class="space-y-4">
        <li
          v-if="pending && !data"
          v-for="_ in Array(3)"
          class="grid grid-cols-[2.25rem_1fr] gap-4"
        >
          <Skeleton class="h-9 !rounded-full" />
          <Skeleton class="h-6 my-auto" />
        </li>
        <li
          v-for="reaction in data?.data"
          class="grid grid-cols-[2.25rem_1fr] gap-4"
        >
          <UserAvatar
            :user="getRelationship(reaction, 'user')"
            :size="36"
            class="my-auto"
          />
          <div class="grid grid-cols-[65fr_35fr] gap-4">
            <TheNuxtLink
              :to="linkTo(getRelationship(reaction, 'user', true))"
              class="font-medium my-auto truncate flex-grow"
            >
              {{
                getRelationship(reaction, "user", true).attributes
                  .displayName ??
                getRelationship(reaction, "user", true).attributes.username
              }}
            </TheNuxtLink>
            <div class="ml-auto flex items-center space-x-2">
              <span class="text-xs sm:text-sm opacity-70 capitalize">
                {{ reaction.attributes.reactionType }}
              </span>
              <AsyncImage
                :src="
                  getAbsoluteAssetLink(
                    `nami/reactions/${reaction.attributes.reactionType}.png`,
                  )
                "
                class="w-6 h-6"
              />
            </div>
          </div>
        </li>
      </ul>
      <NamiPagination
        v-if="data"
        v-model="page"
        :totalItems="data.meta.total"
        :totalPages="Math.ceil(data.meta.total / limit)"
      />
    </div>
  </NamiModal>
</template>

<script setup lang="ts">
import { Reaction, getRelationship, type ReactableResources } from "~/src/api";

const props = defineProps<{
  modelValue: boolean;
  entityId?: string | null;
  noCache?: boolean;
  entityType: ReactableResources;
}>();

defineEmits<{
  (e: "update:modelValue", v: boolean): void;
}>();

const { limit, offset, page } = useInternalPagination();

const { pending, data, execute } = useAsyncData(
  `reactions-${props.entityId}`,
  async () => {
    if (!props.entityId) {
      return null;
    }

    return await Reaction.findReactions(props.entityType, {
      entityIds: [props.entityId],
      limit: limit.value,
      offset: offset.value,
      includes: ["user"],
    });
  },
  {
    server: false,
    immediate: false,
    watch: [limit, offset, () => props.entityId],
  },
);

let executedOnce = false;

watch(
  () => props.modelValue,
  (v) => {
    if (v && (!executedOnce || props.noCache)) {
      executedOnce = true;
      execute();
    }
  },
);
</script>
