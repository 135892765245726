import { useNuxtApp } from "#app";
import { useObservable } from "@vueuse/rxjs";
import { liveQuery } from "dexie";
import { Reaction } from "~/src/api";
import { type DatabaseCommentMeta } from "~/src/namidb";
import { type Ref } from "vue";

const debugStyle =
  "color:white;background-color:rgb(23,152,16);padding:2px 4px;border-radius:4px;";

const defaultMeta = {
  userReaction: null,
  allReactions: {},
  dateUpdated: 0,
};

export const useCommentMeta = (commentId: string) => {
  const nuxtApp = useNuxtApp();
  const authStore = nuxtApp.$auth();
  const initialized = ref(false);
  const config = useRuntimeConfig();
  const silence = config.public.environment === "production";

  const namidb = process.client ? import("../../src/namidb") : null;
  let unwatch: (() => void) | undefined;

  const init = async () => {
    if (!namidb || initialized.value) return;
    const { NamiCacheDatabase } = await namidb;
    const realCommentMeta: Readonly<Ref<undefined | DatabaseCommentMeta>> =
      useObservable(
        // @ts-expect-error TODO: some type clashing here but this is used as intended
        // Note from stavros: dexie's Observable isn't 1-1 assignable to rxjs's Observable
        // If we're using observables over indexDB, I'd suggest we sometime redo this in full rxjs wrapping
        // This is a potential option for saving on bundle size as well
        // Related: https://codereview.stackexchange.com/questions/155834/wrapping-indexdb-with-rxjs
        liveQuery(async () => NamiCacheDatabase.commentMeta.get(commentId)),
      );
    unwatch = watch(realCommentMeta, (v) => (commentMeta.value = v), {
      deep: true,
    });

    if (!realCommentMeta.value?.dateUpdated) await fetchUpdates();

    initialized.value = true;
  };

  onUnmounted(() => unwatch && unwatch());

  const fetchUpdates = async () => {
    if (!namidb) return;
    const { NamiCacheDatabase } = await namidb;
    const meta = await NamiCacheDatabase.commentMeta.get(commentId);
    const timeout = Date.now() - (meta?.dateUpdated ?? 0);
    if (meta && (timeout < 1000 * 60 * 5 || meta.isFetching)) return;
    if (meta)
      await NamiCacheDatabase.commentMeta.update(commentId, {
        isFetching: true,
      });
    else await NamiCacheDatabase.commentMeta.put({ commentId, ...defaultMeta });
    if (!silence)
      console.debug(
        "%c%s",
        debugStyle,
        "CommentMeta",
        "Fetching stats for",
        commentId,
        "\nTimeout:",
        Math.round(60 * 5 - timeout / 1000),
        "s",
      );

    const [allReactions, userReaction] = await Promise.all([
      Reaction.getReactionSummary("comment", commentId),
      authStore?.userEntity
        ? Reaction.findReactions("comment", {
            entityIds: [commentId],
            userIds: [authStore.userEntity.id],
            limit: 1,
          }).then((res) => res.data[0]?.attributes.reactionType ?? null)
        : null,
    ]);

    await NamiCacheDatabase.commentMeta.put({
      commentId,
      userReaction: userReaction,
      allReactions: allReactions.attributes.reactions,
      dateUpdated: Date.now(),
      isFetching: false,
    });
  };

  const updateCommentMeta = async (
    data: Partial<Pick<DatabaseCommentMeta, "userReaction" | "allReactions">>,
  ) => {
    if (!namidb) return;
    const { NamiCacheDatabase } = await namidb;

    if (!silence)
      console.debug(
        "%c%s",
        debugStyle,
        "CommentMeta",
        "Updating stats entry values:",
        data,
        "for",
        commentId,
      );
    return NamiCacheDatabase.commentMeta.update(commentId, data);
  };

  const commentMeta: Ref<undefined | DatabaseCommentMeta> = ref(undefined);

  return {
    commentMeta,
    initialized,

    init,
    updateCommentMeta,
    fetchUpdates,
  };
};

export const setCommentMeta = async (
  commentId: string,
  data: Partial<
    Pick<DatabaseCommentMeta, "userReaction" | "allReactions" | "dateUpdated">
  >,
  replace = false,
) => {
  const namidb = process.client ? import("../../src/namidb") : null;
  if (!namidb) return false;
  const config = useRuntimeConfig();
  const silence = config.public.environment === "production";
  const { NamiCacheDatabase } = await namidb;
  if (!silence)
    console.debug(
      "%c%s",
      debugStyle,
      "CommentMeta",
      "Set stats entry values:",
      data,
      "for",
      commentId,
    );
  if (replace) {
    return NamiCacheDatabase.commentMeta.put({
      commentId,
      ...defaultMeta,
      ...data,
    });
  }
  return NamiCacheDatabase.commentMeta.add({
    commentId,
    ...defaultMeta,
    ...data,
  });
};
export const bulkAddCommentMeta = async (
  data: (Partial<
    Pick<DatabaseCommentMeta, "userReaction" | "allReactions" | "dateUpdated">
  > & { commentId: string })[],
  replace = false,
) => {
  const namidb = process.client ? import("../../src/namidb") : null;
  if (!namidb || !data.length) return false;
  const config = useRuntimeConfig();
  const silence = config.public.environment === "production";
  const { NamiCacheDatabase } = await namidb;
  if (!silence)
    console.debug(
      "%c%s",
      debugStyle,
      "CommentMeta",
      "Adding stats entries for",
      data.map((a) => a.commentId),
    );
  if (replace)
    return NamiCacheDatabase.commentMeta.bulkPut(
      data.map((entry) => ({
        ...defaultMeta,
        ...entry,
      })),
    );
  return NamiCacheDatabase.commentMeta
    .bulkAdd(
      data.map((entry) => ({
        ...defaultMeta,
        ...entry,
      })),
    )
    .catch(
      () =>
        !silence &&
        console.debug(
          "%c%s",
          debugStyle,
          "CommentMeta",
          "Some post meta additions skipped",
        ),
    );
};

export const bulkGetCommentMeta = async (
  commentIds: string[],
): Promise<DatabaseCommentMeta[]> => {
  const namidb = process.client ? import("../../src/namidb") : null;
  if (!namidb) return [];
  const { NamiCacheDatabase } = await namidb;
  return NamiCacheDatabase.commentMeta
    .where("commentId")
    .anyOf(commentIds)
    .toArray()
    .catch(() => []);
};
